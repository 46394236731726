import Timecode from "smpte-timecode";
import {DirectUpload} from "activestorage";

export const IS_DEV = !window.location.host.includes("app");
export const IS_RECORD = $("meta[name=is-record]").attr("content") === "true";
export const getCSRFToken = () => $("meta[name=csrf-token]").attr("content");
export const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

// ========================================= DIRECT ME =========================================

export const shouldIncludeScreenCallItem = (participant) => {
    const trackStatesForInclusion = ["loading", "playable", "interrupted"];
    return (
        trackStatesForInclusion.includes(participant.tracks.screenVideo.state) ||
        trackStatesForInclusion.includes(participant.tracks.screenAudio.state)
    );
};

export const isLocal = (id) => id === "local";

export const isScreenShare = (id) => id.endsWith("-screen");

export const containsScreenShare = (items) =>
    Object.keys(items).some((id) => isScreenShare(id));

export const getTrackUnavailableMessage = (kind, trackState) => {
    if (!trackState) return;
    switch (trackState.state) {
        case "blocked":
            if (trackState.blocked.byPermissions) {
                return `${kind} permission denied`;
            } else if (trackState.blocked.byDeviceMissing) {
                return `${kind} device missing`;
            }
            break;
        case "off":
            if (trackState.off.byUser) {
                return `${kind} muted`;
            } else if (trackState.off.byBandwidth) {
                return `${kind} muted to save bandwidth`;
            }
            break;
        case "sendable":
            return `${kind} not subscribed`;
        case "loading":
            return `${kind} loading...`;
        case "interrupted":
            return `${kind} interrupted`;
        case "playable":
            return null;
    }
};

export const getHotsheetWebsocketToken = () => {
    return "GuMALlR9BE3BXNha7yDv";
};

export const getBroadcastWebsocketToken = () => {
    const meta = document.querySelector(
        "meta[name=broadcast-token][current=true]"
    );
    return meta ? meta.getAttribute("content") : "invalid";
};

export const getVideoWebsocketToken = () => {
    const meta = document.querySelector("meta[name=video-token]");
    return meta ? meta.getAttribute("content") : "invalid";
};

export const setBroadcastWebsocketToken = (token) => {
    const meta = document.querySelector(
        "meta[name=broadcast-token][current=true]"
    );
    return meta ? meta.setAttribute("content", token) : "invalid";
};

// ========================================= DELIVER ME =========================================

// =========================================== COMMON ===========================================

export function generateRandomInteger(min = 11111, max = 99999) {
    return Math.floor(min + Math.random() * (max + 1 - min));
}

export const isRailsDevEnv = () => {
    const meta = document.querySelector("meta[name=is_dev_env]");
    return meta ? meta.getAttribute("content") : false;
};

export const displayToast = (content = null) => {
    if (content) {
        try {
            $(".flash-message").html(content);
            $("#flash-message").toast("show");
            $("#flash-message").on("hidden.bs.toast", () => {
                $("#flash-message").remove();
            });
        } catch (e) {
            console.error(e);
        }
    }
};

export const formatFileSize = (bytes, decimalPoint) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000,
        dm = decimalPoint || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const downloadURI = (uri, name) => {
    let link = document.createElement("a");
    if (name) {
        link.setAttribute("download", name);
    }
    link.href = uri;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    setTimeout(() => {
        link.remove();
    }, 100);
};

export const generateTXTTranscriptionFile = (take, transcriptions) => {
    let fileInput = [];
    let today = new Date();
    let date = today.toDateString();

    fileInput.push(take.name);
    fileInput.push("\n\n\n");
    fileInput.push(date);
    fileInput.push("\n\n\n");
    fileInput.push("[VIDEO TRANSCRIPTION]");
    fileInput.push("\n\n\n");

    transcriptions.forEach((transcription) => {
        fileInput.push(transcription.start_time);
        fileInput.push("\n");
        fileInput.push(transcription.speaker_label + ": " + transcription.segment);
        fileInput.push("\n\n\n");
    });
    let blob = new Blob(fileInput, {type: "text/plain"});

    downloadURI(URL.createObjectURL(blob), take.name + ".txt");
};

export const generateDOCXTranscriptionFile = async (take, transcriptions) => {
    let fileInput = [];
    let today = new Date();
    let date = today.toDateString();

    fileInput += take.name;
    fileInput += "<br><br><br>";
    fileInput += date;
    fileInput += "<br><br><br>";
    fileInput += "[VIDEO TRANSCRIPTION]";
    fileInput += "<br><br><br>";

    transcriptions.forEach((transcription) => {
        fileInput += transcription.start_time;
        fileInput += "<br>";
        fileInput += transcription.speaker_label + ": " + transcription.segment;
        fileInput += "<br><br><br>";
    });

    exportHTML(fileInput, take.name);
};

function exportHTML(html, name = "document") {
    const header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>" +
        name +
        "</title></head><body>";
    const footer = "</body></html>";
    const sourceHTML = header + html + footer;

    const source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(sourceHTML);
    const fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.style.height = "1px";
    fileDownload.style.width = "1px";
    fileDownload.href = source;
    fileDownload.download = name + ".doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
}

export const generateCSVTranscriptionFile = (take, transcriptions) => {
    let fileInput = [];

    fileInput.push(["Start Time", "End Time", "Speaker Label", "Segment"]);
    fileInput.push("\n");

    transcriptions.forEach((transcription) => {
        fileInput.push([
            transcription.start_time,
            transcription.end_time,
            transcription.speaker_label,
            transcription.segment,
        ]);
        fileInput.push("\n");
    });
    let blob = new Blob(fileInput, {type: "text/csv"});

    downloadURI(URL.createObjectURL(blob), take.name + ".csv");
};

export const generateTXTTakeLogFile = (take, logs) => {
    let fileInput = [];
    let today = new Date();
    let date = today.toDateString();

    fileInput.push(take.name);
    fileInput.push("\n\n\n");
    fileInput.push(date);
    fileInput.push("\n\n\n");
    fileInput.push("[VIDEO LOG]");
    fileInput.push("\n\n\n");

    function timecode(timecode_in) {
        let tc = new Timecode(take.timecode_in, parseFloat(take.frame_rate));
        tc.add(parseFloat(timecode_in) * parseFloat(take.frame_rate));
        return tc.toString();
    }

    logs.forEach((log) => {
        fileInput.push(timecode(log.timecode_in));
        fileInput.push("\n");
        fileInput.push(log.user_name + ": " + log.comment);
        fileInput.push("\n\n\n");
    });
    let blob = new Blob(fileInput, {type: "text/plain"});

    downloadURI(URL.createObjectURL(blob), take.name + ".txt");
};

export const downloadZip = (data) => {
    // Create a new JSZip instance
    const zip = new JSZip();

    // Create a new CSV file
    const csv = data.map((row) => row.join(",")).join("\n");

    // Add the CSV file to the zip file
    zip.file("data.csv", csv);

    // Generate the zip file
    zip.generateAsync({type: "blob"}).then((content) => {
        // Save the zip file using FileSaver.js
        saveAs(content, "data.zip");
    });
};

const pad = (n) => (n < 10 ? `0${n}` : n);

// here seconds means video time seconds
// timecode means video timecode
// edl-timecode means EDL timecode with contain offset time

export function secondsToTimecode(offset, seconds, fps, dropFrame = false) {
    if (
        !(
            typeof offset === "number" &&
            typeof seconds === "number" &&
            typeof fps === "number"
        )
    ) {
        return "00:00:00:00";
    }
    if (!dropFrame) seconds = (seconds / Math.ceil(fps)) * fps;
    seconds = offset + seconds;
    if (seconds === 0) {
        return "00:00:00:00";
    }
    const h = parseInt(seconds / 3600);
    const m = parseInt((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    const f = parseInt(((seconds % 60) - s) * fps);
    return `${pad(h)}:${pad(m)}:${pad(s)}:${pad(f)}`;
}

export const secondsToDuration = (seconds, fps) => {
    if (seconds === 0) {
        return "00:00:00";
    }
    const m = parseInt((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    const f = parseInt(((seconds % 60) - s) * fps);
    return `${pad(m)}:${pad(s)}:${pad(f)}`;
};

// without frame rate
export const secondsToTimeDuration = (seconds) => {
    if (seconds === 0) {
        return "00:00";
    }
    const h = parseInt(seconds / 3600);
    const m = parseInt((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    return h === 0 ? `${pad(m)}:${pad(s)}` : `${pad(h)}:${pad(m)}:${pad(s)}`;
};

export function timecodeToSeconds(timecode, fps) {
    if (typeof timecode !== "string") {
        return 0;
    }
    const [h, m, s, f] = timecode.split(":").map((i) => parseInt(i));
    return h * 3600 + m * 60 + s + f / fps;
}

export function edlTimecodeToSeconds(
    fps,
    timecode,
    pre_roll_offset_seconds,
    start_hour = 1,
    non_drop_frame = true
) {
    if (typeof fps === "string") {
        fps = parseFloat(fps);
    }
    if (typeof pre_roll_offset_seconds === "string") {
        pre_roll_offset_seconds = parseInt(pre_roll_offset_seconds);
    }
    let seconds = timecodeToSeconds(timecode, fps) - 3600 * start_hour;
    seconds += pre_roll_offset_seconds;
    if (non_drop_frame) {
        seconds = (seconds * Math.ceil(fps)) / fps;
    }
    return seconds;
}

export const precise = (n, d) =>
    Math.round(Number.parseFloat(n) * Math.pow(10, d)) / Math.pow(10, d);

export const convertBlobToBase64 = (blob) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });

export const checkResponseTime = async (url) => {
    if (url) {
        let start = performance.now();
        await fetch(url);
        let end = performance.now();
        console.log(start, end);
        return end - start;
    }
    return 0;
};

export const PromiseAll = (promises, progress, result) => {
    let d = 0,
        per = 0,
        temp = 0;
    progress(0);
    for (const p of promises) {
        p.then((response) => {
            result(response);
            d++;
            temp = parseInt((d * 100) / promises.length);
            if (temp > per) {
                progress((per = temp));
            }
        });
    }
    return Promise.allSettled(promises);
};

export const fetchImg = async ({url, index, ...data}) =>
    url
        ? await fetch(url, {
            cache: "no-cache",
        })
            .then((r) => r.blob())
            .then(convertBlobToBase64)
            .then((base64) => ({
                ...data,
                index: index,
                data: base64,
            }))
        : {index};

export const fetchImgUsingCanvas = ({url, index}) =>
    new Promise((resolve) => {
        if (url) {
            let img = document.createElement("img");
            // let img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
                let canvas = document.createElement("CANVAS");
                let ctx = canvas.getContext("2d");
                canvas.height = this.naturalHeight;
                canvas.width = this.naturalWidth;
                ctx.drawImage(this, 0, 0);
                resolve({data: canvas.toDataURL(), index});
            };
            img.src = url;
            // make sure the load event fires for cached images too
            if (img.complete || img.complete === undefined) {
                img.src =
                    "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
                img.src = url;
            }
        } else {
            resolve({index});
        }
    });

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export const uploadFile = (file, progress) => {
    const url = $("meta[name=upload-url]").attr("content");
    const upload = new DirectUpload(file, url, {
        directUploadWillStoreFileWithXHR: (request) => {
            request.upload.addEventListener("progress", (event) => {
                progress
                && progress((100 * (event.loaded / (event.total || event.totalSize))).toFixed(2), event.loaded);
            });
        },
    });
    return new Promise((resolve) => {
        upload.create((error, blob) => {
            resolve({
                error,
                blob,
            });
        });
    });
};

export const captureScreenshotFromVideo = (video) =>
    new Promise((resolve) => {
        if (!video) {
            video = document.querySelector("[data-html5-video]");
        }
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        ctx.drawImage(video, 0, 0);
        resolve(canvas.toDataURL("image/jpeg"));
    });

export const getImageDimensions = (uri) => {
    return new Promise(function (resolved, rejected) {
        const i = new Image();
        i.onload = function () {
            resolved({width: i.width, height: i.height});
        };
        i.src = uri;
    });
};

export const isLocalhost = window.location.host.includes("localhost");

export const humanFileSize = (size) => {
    if (typeof size !== "number") {
        return "";
    }
    const i = Math.floor(Math.log(size) / Math.log(1024));
    return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        " " +
        ["B", "kB", "MB", "GB", "TB"][i]
    );
};

export const fileType = (type) => {
    if (typeof type === "string") {
        const arr = type.split("/");
        return (arr[arr.length - 1] || "").toUpperCase();
    }
    return "";
};

export const getLanguage = (code) => {
    switch (code) {
        case "en_US":
            return "English";
        case "es_ES":
            return "Spanish";
    }
};

export const getAnalysisStatus = (status) => {
    switch (status) {
        case "pending":
            return "Pending";
        case "success":
            return "Success";
    }
};


export const capitalize = (word) => {
    return this.$toUpperCase(word);
};